import { SDKConfiguration as sdkConfigs } from "./config/sdk-configuration";
import { Logger } from "./logger/logger";
import { SDKHandler } from "./sdk-handler";
import { v4 as uuidv4 } from "uuid";
import { ErrorCode } from "./error-codes";

export class WebPhone {
  private static instance: WebPhone | null = null;
  callbackConfig: any;
  sdkHandler: SDKHandler | null;

  private constructor() {
    this.sdkHandler = null;
    this.assignUiLoggerConfigsToWindow()
    this.callbackConfig = {};
    sdkConfigs?.config.loadScripts().then((result: boolean) => {
      if(result){
        Logger.client.info("web-phone", "constructor", "WebPhone Initializing");        
        this.sdkHandler = new SDKHandler(this.onEvent);
      }else{
        throw new Error(`Error loading scripts`);
      }

    }).catch((err: any) => {
      throw new Error(`Error loading scripts`);
    });
    
  }

  public static get client(): WebPhone {
    if (WebPhone.instance === null) {
      WebPhone.instance = new WebPhone();
    }
    return WebPhone.instance;
  }

  onEvent = (eventName: string, data: any) => {
    Logger.client.info("web-phone", "onEvent", `Event received : ${eventName}, Data : ${JSON.stringify(data)}`);
    switch (eventName) {
      case "DIALING": {
        this.callbackConfig.onDialing(data);
        break;
      }
      case "REMOTE_RINGING": {
        this.callbackConfig.onRinging();
        break;
      }
      case "CONNECTED": {
        this.callbackConfig.onConnected(data.remoteStreams, data.localStreams, data.remoteUserInfo, data.callId);
        break;
      }
      case "DISCONNECTED": {
        this.callbackConfig.onDisconnected();
        break;
      }
      case "DEVICE_INFO": {
        this.callbackConfig.onTriggerDeviceInfoEvent(data);
        break;
      }
      case "LOCAL_MUTE": {
        this.callbackConfig.onLocalAudioMuted();
        break;
      }
      case "LOCAL_UNMUTE": {
        this.callbackConfig.onLocalAudioUnmuted();
        break;
      }
      case "SIGNALLING_DISCONNECT": {
        let errorCode: number; 
        switch(data) {
          case '':
            errorCode = ErrorCode.NETWORK_DISCONNECTED;
            break;
          case 'MaxConcurrentSessionCountReached':
            errorCode = ErrorCode.MAX_CONCURRENT_SESSION_COUNT_REACHED;
            break;
          default:
            errorCode = ErrorCode.SIGNALLING_DISCONNECTED;
        }
        this.callbackConfig.onTriggerSignallingDisconnect(errorCode);
        break;
      }
      case "SIGNALLING_RECONNECT": {
        this.callbackConfig.onTriggerSignallingReconnect();
        break;
      }
      case "SIGNAL_STRENGTH_CHANGED": {
        this.callbackConfig.onSignalStrengthChanged(data);
        break;
      }
      case "DEVICE_CHANGE": {
        this.callbackConfig.onTriggerDeviceChange(data);
        break;
      }
      case "LOGIN_ERROR": {
        let errorCode: number; 
        switch(data) {
          case 'No response from login':
            errorCode = ErrorCode.AUTHENTICATION_FAILED;
            break;
          default:
            errorCode = ErrorCode.AUTHENTICATION_FAILED;
        }
        this.callbackConfig.onLoginError(errorCode);
        break;
      }
      default: {
        break;
      }
    }
  };

  assignUiLoggerConfigsToWindow = () => {

     (window as any).uiLoggerConfigs = {
      uiLoggerServerUrls: sdkConfigs?.config.getConfig().uiLoggerConfigs?.uiLoggerServerUrls || [],
      applicationName: sdkConfigs?.config.getConfig().uiLoggerConfigs?.applicationName || ""
    };
 }


  setConfig = (param: string, value: any) : boolean => {
    return sdkConfigs?.config.setConfig(param, value);
  }

  setCallbackConfig = (config: object) => {
    this.callbackConfig = config;
  };

  clickToCall = (reciever: any) => {
    let reqId = uuidv4();
    Logger.client.setLogId(reqId);
    this.sdkHandler?.currentState.clickToCall("LIVECHAT", reciever, reqId);
  };

  endCall = () => {
    this.sdkHandler?.currentState.endCall();
  };

  mute = (): void => {
    this.sdkHandler?.currentState.mute();
  };

  unMute = (): void => {
    this.sdkHandler?.currentState.unMute();
  };

  sendDTMF = (digit: string): void => {
    this.sdkHandler?.currentState.sendDTMF(digit);
  };

  /*
    
    
    
    toggleVideo(videoPlayer) {
        this.sdkHandler.currentState.toggleVideo(videoPlayer, this.sdkHandler);
    }
    
    toggleShareScreen() {
        this.sdkHandler.currentState.toggleShareScreen(this.sdkHandler);
    }
    
    selectDTMF(num) {
        this.sdkHandler.currentState.selectDTMF(num, this.sdkHandler);
    } */

  // Other methods and properties of the singleton class can go here
}
