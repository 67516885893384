import { SDKConfiguration as sdkConfigs } from "./config/sdk-configuration";
import { Logger } from "./logger/logger";
import { ConnectedState } from "./call-states/connected-state";
import { DialingState } from "./call-states/dialing-state";
import { IdleState } from "./call-states/idle-state";
import { RingingState } from "./call-states/ringing-state";
import { RemoteRingingState } from "./call-states/remote-ringing-state";
import { SignalingFactory } from "./signaling/signaling-factory";
import { SignalingInterface } from "./signaling/signaling-interface";
import { WebRTCStatsCollector } from "./stats/webrtc-stats-collector";

declare const WrsDtmfTones: any;

export class SDKHandler {
  public currentState: IdleState | DialingState | RingingState | ConnectedState | RemoteRingingState;
  #ch: SignalingInterface | null;
  #sessionId: string;
  #callFactory: any;
  #call: any;
  #callType: string;
  #dnis: string;
  #callId: string;
  reqId: string;
  #webRTCStatsCollector: WebRTCStatsCollector | null | undefined;
  public onPhoneEvent: Function;
  constructor(onPhoneEvent: Function) {
    Logger.client.info("sdk-handler", "constructor", "Initializing SDK");
    this.#webRTCStatsCollector = null;
    this.onPhoneEvent = onPhoneEvent;
    //TODO - Need to pass config not null
    this.#ch = null;
    this.#sessionId = "";
    this.#callType = "audio";
    this.#dnis = "";
    this.#callId = "";
    this.reqId = "";
    this.#call = null;
    this.currentState = new IdleState(this);

    this.checkBrowserSupport();
    console.log(`[WebPhoneSDK.sdk-handler.constructor] SDK Configs:\n` +
      `authserver: ${sdkConfigs.config.getConfig().authserver},\n` +
      `commProxy: ${sdkConfigs.config.getConfig().commProxy},\n` +
      `mediaProxy: ${sdkConfigs.config.getConfig().mediaProxy},\n` +
      `mediaProxyId: ${sdkConfigs.config.getConfig().mediaProxyId},\n` +
      `mediaProxyKey: ${sdkConfigs.config.getConfig().mediaProxyKey},\n` +
      `uiLoggerConfigs: ${JSON.stringify(sdkConfigs.config.getConfig().uiLoggerConfigs)},\n` +
      `tenantId: ${sdkConfigs.config.getConfig().tenantId},\n` +
      `loggingConfigs: ${JSON.stringify(sdkConfigs.config.getConfig().loggingConfigs)},\n` +
      `scripts: ${JSON.stringify(sdkConfigs.config.getConfig().scripts)}`
    );

    //Forcefully ending the call before browser tab closes/refreshes
    window.addEventListener('beforeunload', (event) => {
      this.endCall();
      event.preventDefault();
      event.returnValue = "";
    });
  }

  getSessionId() {
    return this.#sessionId ? this.#sessionId : this.reqId;
  }

  onConnected = (remoteStreams: any, localStreams: any, remoteUserInfo: any, callId: any) => {
    this.#callId = callId;
    Logger.client.info("sdk-handler", "onConnected", `${remoteStreams} ${localStreams} ${remoteUserInfo} ${callId} sessionId: ${this.#sessionId}`);
    this.currentState.onConnected(remoteStreams, localStreams, remoteUserInfo, callId);
  };

  onRemoteRinging = (userId: any, callId: any) => {
    this.#callId = callId;
    Logger.client.info("sdk-handler", "onRemoteRinging", `${userId} ${callId} sessionId: ${this.#sessionId}`);
    this.currentState.onRemoteRinging(userId, callId);
  };

  onDisconnected = (reasonCode: any, callId: any) => {
    Logger.client.info("sdk-handler", "onDisconnected", `${reasonCode} ${callId} sessionId: ${this.#sessionId}`);
    this.#ch?.dispose();
    this.#ch = null;
    this.currentState.onDisconnected(reasonCode, callId);
    this.reqId = "";
    this.#sessionId = "";
    Logger.client.setLogId("");
    this.#webRTCStatsCollector?.cleanUpStats();
    this.#webRTCStatsCollector = null;
  };

  onSignalStrengthChanged = (strength: any, callId: any) => {
    Logger.client.info("sdk-handler", "onSignalStrengthChanged", `${strength} ${callId} sessionId: ${this.#sessionId}`);
    this.currentState.onSignalStrengthChanged(strength);
    this.onPhoneEvent("SIGNAL_STRENGTH_CHANGED", strength);
  };

  onFail = (errorCode: any, callId: any, isScreenshare: any) => {
    Logger.client.info("sdk-handler", "onSignalStrengthChanged", `${errorCode} ${callId} ${isScreenshare} sessionId: ${this.#sessionId}`);
    this.currentState.onDisconnected(errorCode, callId);
  };

  onTrace = (message: string) => {
    Logger.client.info("sdk-handler", "onTrace", `${message}`);
  };

  onStats = (statsReport: any, callId: string) => {
    this.#webRTCStatsCollector?.summerizeStatistics(statsReport);
  };
  
  onDeviceChange = (data: any) => {
    this.onPhoneEvent("DEVICE_CHANGE", data);
  }

  onSignalingInitialized = (data: any) => {
    try {
      Logger.client.info("sdk-handler", "onSignalingInitialized", `Signaling initialized successfully - ${JSON.stringify(data)}}`);
      Logger.client.setLogId(data.sid);
      this.#sessionId = data.sid;
      this.reqId = data.sid;
      this.onPhoneEvent("DIALING", { callType: "audio", dnis: this.#dnis, sessionId: this.#sessionId });
      Logger.client.info("sdk-handler", "onSignalingInitialized", "Phone Event Sent");

      //TODO: need to verify if initialization was successfull or not
      Logger.client.info("sdk-handler", "onSignalingInitialized", "Starting AV Call");
      this.startAvCall(1, `ctc:${this.#dnis}`);
    } catch (error: any) {
      Logger.client.error("sdk-handler", "onSignalingInitialized", `Error occurred ${error.message}`, error);
    }
  };

  onSignalingFailed = (err: any) => {
    try {
      Logger.client.error("sdk-handler", "onSignalingFailed", `Signal initialization failed ${err.message} sessionId: ${this.#sessionId}`, err);
    } catch (error: any) {
      Logger.client.error("sdk-handler", "onSignalingFailed", `Error occurred ${error.message}`, error);
    }
  };

  onSignalingDisconnected = (reason: string) => {
    Logger.client.info("sdk-handler", "onSignalingDisconnected", `Signalling Disconnected ${reason}`);
    this.onPhoneEvent("SIGNALLING_DISCONNECT", reason);
  };

  onSignalingReconnected = () => {
    Logger.client.info("sdk-handler", "onSignalingReconnected", "Signalling Reconnected");
    this.onPhoneEvent("SIGNALLING_RECONNECT");
  };

  private getAvConfig = () => {
    return {
      MediaTransport: {
        iceServers: [
          {
            urls: sdkConfigs?.config.getConfig().mediaProxy,
            credential: sdkConfigs?.config.getConfig().mediaProxyKey,
            username: sdkConfigs?.config.getConfig().mediaProxyId,
          },
        ],
        iceTransportPolicy: "all",
      },
      Phone: {
        Number: "+940123456789" , //"+6591345015",
      },
      Display: {
        OnConnected: this.onConnected,
        OnDisconnected: this.onDisconnected,
        OnSignalStrengthChange: this.onSignalStrengthChanged,
        OnFail: this.onFail,
        OnRemoteRinging: this.onRemoteRinging,
        OnTrace: this.onTrace,
        OnStats: this.onStats,
        OnDeviceChange: this.onDeviceChange

      },
    };
  };

  getCurrentState = (): IdleState | DialingState | RingingState | ConnectedState | RemoteRingingState => {
    return this.currentState;
  };

  setCurrentState = (state: IdleState | DialingState | RingingState | ConnectedState | RemoteRingingState): void => {
    this.currentState = state;
  };

  initialize = (): void => {
    try {
      Logger.client.info("sdk-handler", "initialize", `Trying to initialize signaling`);
      this.#ch?.initialize({ authCode: sdkConfigs?.config.getConfig().authCode }, this.reqId).then((channel: any) => {
        //create factory same
        this.#call = this.#ch?.createCall(this.getAvConfig());
      });
    } catch (error: any) {
      Logger.client.error("sdk-handler", "initialize", `Error occurred ${error.message}`, error);
    }
  };

  clickToCall = (signalMode: string, dnis: string, reqId: string): void => {
    this.reqId = reqId;
    Logger.client.info("sdk-handler", "clickToCall", `Initiating click to call - ${dnis}`);
    this.#ch = new SignalingFactory().create(signalMode, { livechatCon: sdkConfigs?.config.getConfig().commProxy }, this);
    this.#callType = "ctc";
    this.#dnis = dnis;
    this.initialize();
  };

  muteUnmuteCall = (muteAudio: boolean): boolean => {
    try {
      this.#call.Pause(muteAudio, true);
      return true;
    } catch (error) {
      Logger.client.error("sdk-handler", "muteUnmuteCall", `Error occurred muting/unmuting call`, error);
      return false;
    }
  };

  sendDTMF = (num: string) => {
    try {
      switch (num) {
        case "0":
          this.#call.sendDtmf(WrsDtmfTones.NUM_0);
          break;
        case "1":
          this.#call.sendDtmf(WrsDtmfTones.NUM_1);
          break;
        case "2":
          this.#call.sendDtmf(WrsDtmfTones.NUM_2);
          break;
        case "3":
          this.#call.sendDtmf(WrsDtmfTones.NUM_3);
          break;
        case "4":
          this.#call.sendDtmf(WrsDtmfTones.NUM_4);
          break;
        case "5":
          this.#call.sendDtmf(WrsDtmfTones.NUM_5);
          break;
        case "6":
          this.#call.sendDtmf(WrsDtmfTones.NUM_6);
          break;
        case "7":
          this.#call.sendDtmf(WrsDtmfTones.NUM_7);
          break;
        case "8":
          this.#call.sendDtmf(WrsDtmfTones.NUM_8);
          break;
        case "9":
          this.#call.sendDtmf(WrsDtmfTones.NUM_9);
          break;
        case "*":
          this.#call.sendDtmf(WrsDtmfTones.Star);
          break;
        case "#":
          this.#call.sendDtmf(WrsDtmfTones.Pound);
          break;
      }
    } catch (error) {
      Logger.client.error("sdk-handler", "sendDTMF", `Error occurred while sending dtmf`, error);
    }
  };

  endCall = (): void => {
    this.#call?.EndAvCall();
    this.currentState.onDisconnected("Local disconnect", this.#callId);
    setTimeout(() => {
      this.#ch?.dispose();
      this.#ch = null;
      this.reqId = "";
      this.#sessionId = "";
      Logger.client.setLogId("");
      this.#webRTCStatsCollector?.cleanUpStats();
      this.#webRTCStatsCollector = null;
    }, 1000);
  };

  private startAvCall = (callType: number, dnis: string): void => {
    Logger.client.info("sdk-handler", "clickToCall", `Start AV Call ${JSON.stringify({ sessionId: this.#sessionId, dnis, callType })}`);
    this.#webRTCStatsCollector = new WebRTCStatsCollector();
    this.#call.StartAvCall(this.#sessionId, dnis, {
      call: callType,
      externalId: null,
    });
  };

  /**
   * check browser support for
   * microphone, webcam, screenshare & WebRTC
   */
  checkBrowserSupport = (): void => {
    //call sdk function to check the device support

    //TODO : Check if we can use local variable and use 1 to access call sdk
    WrsUtils.checkDeviceSupport()
      .then((deviceInfo: any) => {
        const deviceData = {
          hasMicrophone: deviceInfo.hasMicrophone,
          hasWebcam: deviceInfo.hasWebcam,
          microphoneAlreadyCaptured: deviceInfo.isMicrophoneAlreadyCaptured,
          screenshareSupported: deviceInfo.isScreenshareSupported,
          webRTCSupported: deviceInfo.isWebRTCSupported,
          webcamAlreadyCaptured: deviceInfo.isWebcamAlreadyCaptured,
        };

        this.onPhoneEvent("DEVICE_INFO", deviceData);
      })
      .catch((error: any) => {
        Logger.client.error("sdk-handler", "checkBrowserSupport", `checking browser support ${error.message}`, error);
      });
  };
}
