
/*
  This file loads a configuration object based on the specified environment. The environment is determined by the 'env' property,
  which can be set to either 'production' or 'development'. 
  
  Depending on the environment, the appropriate configuration data is retrieved from the respective JSON files, 'production.json' or 'development.json', and assigned to the 'Config' variable. The 'Config' object is
  then exported for use in the application.
*/

import productionConfig from './production.json';
import developmentConfig from './development.json';

const environment = {
  env: "development"  
}

class SDKConfiguration {
  private static instance: SDKConfiguration | null = null;
  #config: any;

  private constructor() {
    // Private constructor to prevent instantiation outside the class
    this.#config = {};

    switch (environment.env) {
      case 'production':
        this.#config = productionConfig;
        break;
      case 'development':
        this.#config = developmentConfig;
        break;
      default:
        this.#config = developmentConfig;
        break;
    }
  }

  public static get config(): SDKConfiguration {
    if (!SDKConfiguration.instance) {
      SDKConfiguration.instance = new SDKConfiguration();
    }
    return SDKConfiguration.instance;
  }

  public loadScript(src: string): Promise<boolean>{
    return new Promise<boolean>((resolve, reject) => {
      try {
        // Create a new script element
        let script = document.createElement('script');
  
        // Set the source of the script to script2.js
        script.src = src;
  
        script.onload = () => {
          resolve(true);  
        };

        script.onerror = () => {
          reject(new Error(`Error loading script from ${src}`));  
        };
  
        // Find an existing script element in the document (you can use a specific identifier)
        let existingScript = document.querySelector('script[src="lib/tetherfi-webphone-sdk.js"]');
  
        // Insert script2.js before the existing script
        document.body.insertBefore(script, existingScript);
        
      } catch (error: any) {
        reject(new Error(`Error loading script from ${src} - ${error.message}`)); 
      }

    });
    
  }

  public loadScripts(): Promise<boolean>{
    return new Promise<boolean>((resolve, reject) => {
      try {
        let scripts = this.#config.scripts.map((script: string) => {
          return this.loadScript(script);
        })
        Promise.all(scripts).then((result: any) => {
          resolve(true)
        }).catch((err) => {
          resolve(false);
        });
        
      } catch (error) {
        console.error(`Error loading scripts`, error);
        resolve(false);      
      }

    })
    

  }

  public getConfig(): any {
    return this.#config;
  }

  public setConfig(param: string, value: any): boolean {

    switch(param){
      case "AUTH_CODE":{
        if(typeof(value) === 'string'){
          this.#config.authCode = value;
          return true;
        }else{
          return false;
        }
      }
      case "TENANT_ID":{
        if(typeof(value) === 'string'){
          this.#config.tenantId = value;
          return true;
        }else{
          return false;
        }
      }
      default :{
        return false;
      }
    }
  }

  // Your other class methods and properties go here
}

export { SDKConfiguration };


