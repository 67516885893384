import { LogInterface } from "../logger/log-interface";
import { Logger } from "../logger/logger";
import { IState } from "./state-interface";
import { SDKHandler } from "../sdk-handler";
import { DialingState } from "./dialing-state";

export class IdleState implements IState {
  #sdkHandler: SDKHandler;
  constructor(sdkHandler: SDKHandler) {
    this.#sdkHandler = sdkHandler;
    Logger.client.info("idle-state", "constructor", `Switched to idle state`);
  }
  mute = (): void => {
    Logger.client.warn("idle-state", "unMute", `Method not implemented`);
  };
  unMute = (): void => {
    Logger.client.warn("idle-state", "unMute", `Method not implemented`);
  };
  clickToCall = (signalMode: string, dnis: string, reqId: string): void => {
    this.#sdkHandler.reqId = reqId;
    Logger.client.debug("idle-state", "clickToCall", `Click to Call - ${JSON.stringify({ dnis })}`);
    this.#sdkHandler.setCurrentState(new DialingState(this.#sdkHandler));
    this.#sdkHandler.clickToCall(signalMode, dnis, reqId);
  };
  makeCall = (callType: string, dnis: string): void => {
    Logger.client.warn("idle-state", "makeCall", ` Method not implemented`);
  };
  endCall = (): void => {
    Logger.client.debug("idle-state", "endCall", `[${this.#sdkHandler.getSessionId()}]`);
    this.#sdkHandler.endCall();
  };
  toggleVideo = (videoPlayer: any): void => {
    Logger.client.warn("idle-state", "toggleVideo", `Method not implemented`);
  };
  toggleShareScreen = (): void => {
    Logger.client.warn("idle-state", "toggleShareScreen", `Method not implemented`);
  };
  startVideo = (): void => {
    Logger.client.warn("idle-state", "startVideo", `Method not implemented`);
  };
  stopVideo = (): void => {
    Logger.client.warn("idle-state", "stopVideo", `Method not implemented`);
  };
  startScreenshare = (): void => {
    Logger.client.warn("idle-state", "startScreenshare", `Method not implemented`);
  };
  stopScreenshare = (): void => {
    Logger.client.warn("idle-state", "stopScreenshare", `Method not implemented`);
  };
  sendDTMF = (digit: string): void => {
    Logger.client.warn("idle-state", "selectDTMF", `Method not implemented`);
  };
  clickToStartVideo = (sdkHandler: any): void => {
    Logger.client.warn("idle-state", "clickToStartVideo", `Method not implemented`);
  };
  clickToDialPad = (): void => {
    Logger.client.warn("idle-state", "clickToDialPad", `Method not implemented`);
  };
  clickToCloseDialPad = (): void => {
    Logger.client.warn("idle-state", "clickToCloseDialPad", `Method not implemented`);
  };
  dialNumber = (number: any): void => {
    Logger.client.warn("idle-state", "dialNumber", `Method not implemented`);
  };
  onChannelOpen = (callType: any, sessionId: any): void => {
    Logger.client.warn("idle-state", "onChannelOpen", `Method not implemented`);
  };
  onConnected = (remoteStreams: any, localStreams: any, remoteUserInfo: any, callId: any): void => {
    Logger.client.warn("idle-state", "onConnected", `Method not implemented`);
  };
  onDisconnected = (reasonCode: any, callId: any): void => {
    Logger.client.warn("idle-state", "onDisconnected", `Method not implemented`);
  };
  onRemoteRinging = (userId: any, callId: any): void => {
    Logger.client.warn("idle-state", "onRemoteRinging", `Method not implemented`);
  };
  onSignalStrengthChanged = (strength: any): void => {
    Logger.client.warn("idle-state", "onSignalStrengthChanged", `Method not implemented`);
  };
  onStartVideoCall = (videoPlayer: any, call: any): void => {
    Logger.client.warn("idle-state", "onStartVideoCall", `Method not implemented`);
  };
}
