import { SDKConfiguration as sdkConfigs } from '../config/sdk-configuration';
export class UiLogger {
  #loggerIsReady
  constructor(){
    this.#loggerIsReady = false;
    this.initializeUiLogger();
  }

  private generateLogDateTime = () : string => {
    try{
      const date = new Date();
      return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + ":" + date.getMilliseconds()).toString();
    }catch(error: any){
      return "";
    }    
  }

  loggingStartEvent = (fileName: string) => {
    this.#loggerIsReady = true;
    console.log(`[${this.generateLogDateTime()}] - [WebPhoneSDK.logger.loggingStartEvent] - [${sdkConfigs?.config?.getConfig().tenantId}] - UI Logger Started - ${fileName}`);
  } 

  initializeUiLogger = () => { 
    try {
        (window as any).uiLoggerConfigs = {
            uiLoggerServerUrls: sdkConfigs?.config?.getConfig().uiLoggerConfigs?.uiLoggerServerUrls || [],
            applicationName: sdkConfigs?.config?.getConfig().uiLoggerConfigs?.applicationName || ""
        };
        (window as any).LoggingStartEvent = this.loggingStartEvent;
        
    } catch (error: any) {
        console.log(`[${this.generateLogDateTime()}] - [WebPhoneSDK.logger.initializeUiLogger] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error initializing logger`, error);            
    }        
  }

  info = (className: string, methodName: string, message: string): void => {
    try {
      if(this.#loggerIsReady){
        window?.uiLoggerSdk?.LogInfo(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
      }      
    } catch (error: any) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.info] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error sending message to ui logger`, error);  
    }
  };
  
  debug = (className: string, methodName: string, message: string): void => {
    try {
      if(this.#loggerIsReady){
        window?.uiLoggerSdk?.LogDebug(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
      } 
      
    } catch (error) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.debug] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error sending message to ui logger`, error);  
    }
  };
  
  warn = (className: string, methodName: string, message: string): void => {
    try {
      if(this.#loggerIsReady){
        window?.uiLoggerSdk?.LogWarn(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
      }      
    } catch (error) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.warn] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error sending message to ui logger`, error);  
    }
  };
  
  fatal = (className: string, methodName: string, message: string): void => {
    try {
      if(this.#loggerIsReady){
        window?.uiLoggerSdk?.LogFatal(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
      } 
    } catch (error) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.fatal] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error sending message to ui logger`, error);  
    }
  }
  
  error = (className: string, methodName: string, message: string): void => {
    try {
      if(this.#loggerIsReady){
        window?.uiLoggerSdk?.LogError(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
      }      
    } catch (error) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.error] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error sending message to ui logger`, error);  
    }
  };
  
  log = (type: string, className: string, methodName: string, message: string) => {
    try {
      if(this.#loggerIsReady){
        switch (type) {
          case "error":
            window?.uiLoggerSdk?.LogError(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
            break;
          case "fatal":
            window?.uiLoggerSdk?.LogFatal(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
              break;
          case "warn":
            window?.uiLoggerSdk?.LogWarn(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
            break;
          case "debug":
            window?.uiLoggerSdk?.LogDebug(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
            break;
          case "info":
            window?.uiLoggerSdk?.LogInfo(`WebPhoneSDK.${className}`, methodName, `[${this.generateLogDateTime()}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
            break;
          default:
            break;
        }
      } 
      
    } catch (error: any) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.log] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error sending message to ui logger`, error);  
    }
  };
  
  closeLogger = () => {
    try {
      window?.uiLoggerSdk?.CloseLogger();
      this.#loggerIsReady = false;
    } catch (error: any) {
      console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.ui-logger.closeLogger] - [${sdkConfigs?.config?.getConfig().tenantId}] - Error closing ui logger`, error);  
    }
  };
}



  
  