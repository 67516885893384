
import { LogInterface } from '../logger/log-interface'; 
import { Logger } from '../logger/logger'; 
import {LivechatSignaling} from './livechat-signaling';
import {SignalingInterface} from './signaling-interface';

export class SignalingFactory{
    constructor(){
    }

    create = (type: string, config: any, sdkHandler: any) : SignalingInterface => {
        Logger.client.info("signalling-factory", "create", "Creating signaling factory");
        if(type === 'LIVECHAT'){
            return new LivechatSignaling(config, sdkHandler) as SignalingInterface;
        }else{
            return new LivechatSignaling(config, sdkHandler) as SignalingInterface;
        }
    }
}