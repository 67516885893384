
/**
 * Enum containing error codes and ranges for different error categories.
 * Ranges:
 * - Errors: 1000 - 1999
 *   - Signalling Errors: 1000 - 1499
 *   - Login Errors: 1500 - 1999
 * - Warnings: 2000 - 2999
 * - Other messages: 3000 - 3999
 */

export enum ErrorCode {
    /**
	 * Network disconnection during a call.
	 */
    NETWORK_DISCONNECTED = 1001,

    /**
	 * Signalling disconnection during a call.
	 */
    SIGNALLING_DISCONNECTED = 1002,

    /**
	 * No valid credentials for the registartion purpose.
	 */
    AUTHENTICATION_FAILED = 1501,

    /**
	 * Maximum concurrent session limit has exceeded.
	 */
    MAX_CONCURRENT_SESSION_COUNT_REACHED = 2001,
}