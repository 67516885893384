
import { SDKConfiguration as sdkConfigs } from '../config/sdk-configuration';
import {LogInterface} from './log-interface';
import { UiLogger } from './ui-logger';
import { ConsoleLogger } from './console-logger';
import { SDKHandler } from '../sdk-handler';  

export class Logger implements LogInterface {
    private static instance: Logger | null = null;
    #uiLogger: any;
    #consoleLogger: any;
    #logId: string;

    private constructor(){
        this.#logId = "";
        if(sdkConfigs?.config.getConfig().loggingConfigs?.useUiLogging){
            this.#uiLogger = new UiLogger();
        }
        if(sdkConfigs?.config.getConfig().loggingConfigs?.useConsoleLogging){
            this.#consoleLogger = new ConsoleLogger();
        }
    }
    public static get client(): Logger {
        if (Logger.instance === null) {
            Logger.instance = new Logger();
        }
        return Logger.instance;
    }
    
    setLogId = (logId: string) => {
        this.#logId = logId;
    }

    info = (className: string, methodName: string, message: string) => {
        this.#uiLogger?.info(`${className}`, methodName, `[${this.#logId}] - ${message}`);
        this.#consoleLogger?.info(`${className}`, methodName, `[${this.#logId}] - ${message}`);
    }

    debug = (className: string, methodName: string, message: string) => {
        this.#uiLogger?.debug(`${className}`, methodName, `[${this.#logId}] - ${message}`);
        this.#consoleLogger?.debug(`${className}`, methodName, `[${this.#logId}] - ${message}`);
    }

    warn = (className: string, methodName: string, message: string) => {
        this.#uiLogger?.warn(`${className}`, methodName, `[${this.#logId}] - ${message}`);
        this.#consoleLogger?.warn(`${className}`, methodName, `[${this.#logId}] - ${message}`);
    }

    error = (className: string, methodName: string, message: string, error: any = null) => {
        this.#uiLogger?.error(`${className}`, methodName, `[${this.#logId}] - ${message}`);
        this.#consoleLogger?.error(`${className}`, methodName, `[${this.#logId}] - ${message}`, error);
    }
}