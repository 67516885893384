import { Logger } from "../logger/logger";
import { IState } from "./state-interface";
import { SDKHandler } from "../sdk-handler";
import { RemoteRingingState } from "./remote-ringing-state";
import { ConnectedState } from "./connected-state";
import { IdleState } from "./idle-state";

export class DialingState implements IState {
  #sdkHandler: SDKHandler;
  constructor(sdkHandler: SDKHandler) {
    Logger.client.info("dialing-state", "constructor", "Switched to dialing state");
    this.#sdkHandler = sdkHandler;
  }
  mute = (): void => {
    Logger.client.warn("dialing-state", "clickToCall", `Method not implemented`);
  };
  unMute = (): void => {
    Logger.client.warn("dialing-state", "clickToCall", `Method not implemented`);
  };

  clickToCall = (signalMode: string, dnis: string, reqId: string): void => {
    Logger.client.warn("dialing-state", "clickToCall", `Method not implemented`);
  };
  makeCall = (callType: string, dnis: string): void => {
    Logger.client.warn("dialing-state", "makeCall", `Method not implemented`);
  };
  endCall = (): void => {
    Logger.client.info("dialing-state", "endCall", "");
    this.#sdkHandler.endCall();
  };
  toggleVideo = (videoPlayer: any): void => {
    Logger.client.warn("dialing-state", "toggleVideo", `Method not implemented`);
  };
  toggleShareScreen = (): void => {
    Logger.client.warn("dialing-state", "toggleShareScreen", `Method not implemented`);
  };
  startVideo = (): void => {
    Logger.client.warn("dialing-state", "startVideo", `Method not implemented`);
  };
  stopVideo = (): void => {
    Logger.client.warn("dialing-state", "stopVideo", `Method not implemented`);
  };
  startScreenshare = (): void => {
    Logger.client.warn("dialing-state", "startScreenshare", `Method not implemented`);
  };
  stopScreenshare = (): void => {
    Logger.client.warn("dialing-state", "stopScreenshare", `Method not implemented`);
  };
  sendDTMF = (digit: string): void => {
    Logger.client.warn("dialing-state", "selectDTMF", `Method not implemented`);
  };
  clickToStartVideo = (sdkHandler: any): void => {
    Logger.client.warn("dialing-state", "clickToStartVideo", `Method not implemented`);
  };
  clickToDialPad = (): void => {
    Logger.client.warn("dialing-state", "clickToDialPad", `Method not implemented`);
  };
  clickToCloseDialPad = (): void => {
    Logger.client.warn("dialing-state", "clickToCloseDialPad", `Method not implemented`);
  };
  dialNumber = (number: any): void => {
    Logger.client.warn("dialing-state", "dialNumber", `Method not implemented`);
  };
  onChannelOpen = (callType: any, sessionId: any): void => {
    Logger.client.warn("dialing-state", "onChannelOpen", `Method not implemented`);
  };
  onConnected = (remoteStreams: any, localStreams: any, remoteUserInfo: any, callId: any): void => {
    Logger.client.info("dialing-state", "onConnected", `On Connected - ${JSON.stringify({ remoteUserInfo, callId })}`);
    this.#sdkHandler.setCurrentState(new ConnectedState(this.#sdkHandler));
    this.#sdkHandler.onPhoneEvent("CONNECTED", { remoteStreams, localStreams, remoteUserInfo, callId });
  };
  onDisconnected = (reasonCode: any, callId: any): void => {
    Logger.client.info("dialing-state", "onDisconnected", `On Disconnected - ${JSON.stringify({ reasonCode, callId })}`);
    this.#sdkHandler.setCurrentState(new IdleState(this.#sdkHandler));
    this.#sdkHandler.onPhoneEvent("DISCONNECTED", { reasonCode, callId });
  };
  onRemoteRinging = (userId: any, callId: any): void => {
    Logger.client.info("dialing-state", "onRemoteRinging", `On Remote Ringing - ${JSON.stringify({ userId, callId })}`);
    this.#sdkHandler.setCurrentState(new RemoteRingingState(this.#sdkHandler));
    this.#sdkHandler.onPhoneEvent("REMOTE_RINGING", { userId, callId });
  };
  onSignalStrengthChanged = (strength: any): void => {
    Logger.client.warn("dialing-state", "onSignalStrengthChanged", `Method not implemented`);
  };
  onStartVideoCall = (videoPlayer: any, call: any): void => {
    Logger.client.warn("dialing-state", "onStartVideoCall", `Method not implemented`);
  };
}
