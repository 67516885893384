import { Logger } from "../logger/logger";

export class WebRTCStatsCollector {
  #webRTCStatsSummary: any;
  #statsCollection: Array<any>;
  #intervalRef: any;
  constructor() {
    this.#intervalRef = null;
    this.#statsCollection = [];
    this.#webRTCStatsSummary = {
      duration: 0,
      mos: {
        min: 0,
        max: 0,
      },
      packetsLost: "",
      ASB: {
        min: 0,
        max: 0,
      },
      jitter: {
        min: 0,
        max: 0,
      },
      ABS: "",
      ABR: "",
      VBS: "",
      VBR: "",
    };
  }

  sendStats = (): void => {
    Logger.client.debug("webrtc-stats-collector", "sendStatsPeriodically", `Periodic Stats : ${JSON.stringify(this.#statsCollection)}`);
    this.#statsCollection = [];
  }

  sendStatsPeriodically = (): void => {
    this.#intervalRef = setInterval(this.sendStats, 5000);
  }

  summerizeStatistics = (result: any): void => {
    try {
      if (result) {
        if(!this.#intervalRef && this.#statsCollection && this.#statsCollection.length === 0){
          this.sendStatsPeriodically();
        }
        this.#statsCollection.push(result);
        this.#webRTCStatsSummary.duration = 0;
        this.#webRTCStatsSummary.audioPacketsLost = result.audio.local.packetsLost;
        this.#webRTCStatsSummary.videoPacketsLost = result.video.local.packetsLost;

        const asb = result.stats.network.availableSendBandwidth;
        if (this.#webRTCStatsSummary.ASB.min == 0 || this.#webRTCStatsSummary.ASB.min > asb) {
          this.#webRTCStatsSummary.ASB.min = asb;
        }
        if (this.#webRTCStatsSummary.ASB.max == 0 || this.#webRTCStatsSummary.ASB.max < asb) {
          this.#webRTCStatsSummary.ASB.max = asb;
        }

        const mos = result.audio.local.mos;
        if (this.#webRTCStatsSummary.mos.min == 0 || this.#webRTCStatsSummary.mos.min > mos) {
          this.#webRTCStatsSummary.mos.min = mos;
        }
        if (this.#webRTCStatsSummary.mos.max == 0 || this.#webRTCStatsSummary.mos.max < mos) {
          this.#webRTCStatsSummary.mos.max = mos;
        }

        const jitter = result.audio.local.jitter;
        if (this.#webRTCStatsSummary.jitter.min == 0 || this.#webRTCStatsSummary.jitter.min > jitter) {
          this.#webRTCStatsSummary.jitter.min = jitter;
        }
        if (this.#webRTCStatsSummary.jitter.max == 0 || this.#webRTCStatsSummary.jitter.max < jitter) {
          this.#webRTCStatsSummary.jitter.max = jitter;
        }

        this.#webRTCStatsSummary.audioBytesSent = result.audio.local.bytesSent;
        this.#webRTCStatsSummary.audioBytesReceived = result.audio.remote.bytesReceived;
        this.#webRTCStatsSummary.videoBytesSent = result.video.local.bytesSent;
        this.#webRTCStatsSummary.videoBytesReceived = result.video.remote.bytesReceived;
      }
    } catch (error) {}
  };

  cleanUpStats = () => {
    try {
      Logger.client.debug("webrtc-stats-collector", "cleanUpStats", `Summerized Stats : ${JSON.stringify(this.#webRTCStatsSummary)}`);
      clearInterval(this.#intervalRef);
      this.sendStats();
      this.#webRTCStatsSummary = {
        duration: 0,
        mos: {
          min: 0,
          max: 0,
        },
        packetsLost: "",
        ASB: {
          min: 0,
          max: 0,
        },
        jitter: {
          min: 0,
          max: 0,
        },
        ABS: "",
        ABR: "",
        VBS: "",
        VBR: "",
      };
    } catch (error: any) {
      Logger.client.error("webrtc-stats-collector", "cleanUpStats", `Error occurred on cleaning up stats`, error);
    }
  };
}
