import { Logger } from "../logger/logger";
import { IState } from "./state-interface";
import { SDKHandler } from "../sdk-handler";
import { IdleState } from "./idle-state";

export class ConnectedState implements IState {
  #sdkHandler: SDKHandler;
  #isLocalAudioMuted: boolean;
  #isRemoteAudioMuted: boolean;
  constructor(sdkHandler: SDKHandler) {
    Logger.client.info("connected-state", "constructor", "Switched to connected state");
    this.#sdkHandler = sdkHandler;
    this.#isLocalAudioMuted = false;
    this.#isRemoteAudioMuted = false;
  }
  mute = (): void => {
    Logger.client.info("connected-state", "mute", `mute action`);
    if (!this.#isLocalAudioMuted) {
      if (this.#sdkHandler.muteUnmuteCall(true)) {
        this.#isLocalAudioMuted = true;
        this.#sdkHandler.onPhoneEvent("LOCAL_MUTE", null);
      }
    }
  };
  unMute = (): void => {
    Logger.client.info("connected-state", "unMute", `un mute action`);
    if (this.#isLocalAudioMuted) {
      if (this.#sdkHandler.muteUnmuteCall(false)) {
        this.#isLocalAudioMuted = false;
        this.#sdkHandler.onPhoneEvent("LOCAL_UNMUTE", null);
      }
    }
  };
  clickToCall = (signalMode: string, dnis: string, reqId: string): void => {
    Logger.client.warn("connected-state", "clickToCall", `Method not implemented`);
  };
  makeCall = (callType: string, dnis: string): void => {
    Logger.client.warn("connected-state", "makeCall", `Method not implemented`);
  };
  endCall = (): void => {
    Logger.client.info("connected-state", "endCall", ``);
    this.#sdkHandler.endCall();
  };
  toggleVideo = (videoPlayer: any): void => {
    Logger.client.warn("connected-state", "toggleVideo", `Method not implemented`);
  };
  toggleShareScreen = (): void => {
    Logger.client.warn("connected-state", "toggleShareScreen", `Method not implemented`);
  };
  startVideo = (): void => {
    Logger.client.warn("connected-state", "startVideo", `Method not implemented`);
  };
  stopVideo = (): void => {
    Logger.client.warn("connected-state", "stopVideo", `Method not implemented`);
  };
  startScreenshare = (): void => {
    Logger.client.warn("connected-state", "startScreenshare", `Method not implemented`);
  };
  stopScreenshare = (): void => {
    Logger.client.warn("connected-state", "stopScreenshare", `Method not implemented`);
  };
  sendDTMF = (digit: string): void => {
    Logger.client.info("connected-state", "selectDTMF", `DTMF Received : ${digit}`);
    this.#sdkHandler.sendDTMF(digit);
  };
  clickToStartVideo = (sdkHandler: any): void => {
    Logger.client.warn("connected-state", "clickToStartVideo", `Method not implemented`);
  };
  clickToDialPad = (): void => {
    Logger.client.warn("connected-state", "clickToDialPad", `Method not implemented`);
  };
  clickToCloseDialPad = (): void => {
    Logger.client.warn("connected-state", "clickToCloseDialPad", `Method not implemented`);
  };
  dialNumber = (number: any): void => {
    Logger.client.warn("connected-state", "dialNumber", `Method not implemented`);
  };
  onChannelOpen = (callType: any, sessionId: any): void => {
    Logger.client.warn("connected-state", "onChannelOpen", `Method not implemented`);
  };
  onConnected = (remoteStreams: any, localStreams: any, remoteUserInfo: any, callId: any): void => {
    Logger.client.warn("connected-state", "onConnected", `Method not implemented`);
  };
  onDisconnected = (reasonCode: any, callId: any): void => {
    Logger.client.info("connected-state", "onDisconnected", `On Disconnected - ${JSON.stringify({ reasonCode, callId })}`);
    this.#sdkHandler.setCurrentState(new IdleState(this.#sdkHandler));
    this.#sdkHandler.onPhoneEvent("DISCONNECTED", { reasonCode, callId });
  };
  onRemoteRinging = (userId: any, callId: any): void => {
    Logger.client.warn("connected-state", "onRemoteRinging", `Method not implemented`);
  };
  onSignalStrengthChanged = (strength: any): void => {
    Logger.client.warn("connected-state", "onSignalStrengthChanged", `Method not implemented`);
  };
  onStartVideoCall = (videoPlayer: any, call: any): void => {
    Logger.client.warn("connected-state", "onStartVideoCall", `Method not implemented`);
  };
}
