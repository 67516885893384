
import { LogInterface } from '../logger/log-interface'; 
import { Logger } from '../logger/logger'; 
import { SDKConfiguration as sdkConfigs } from '../config/sdk-configuration';
import {SignalingInterface} from './signaling-interface';

declare const Livechat2Sdk: any;
declare const LiveChatAvCommInterface: any;
declare const LiveChatAvFactory: any;

export class LivechatSignaling implements SignalingInterface{
    #client: any;
    sdkHandler: any;
    #accessToken: string; 

    livechatEvents: any;   
    constructor(config: any, sdkHandler: any){
        this.sdkHandler = sdkHandler;
        Logger.client.info("livechat-signaling", "constructor", `Initializing livechat client`);
        this.#client = new Livechat2Sdk.Livechat2Api(config?.livechatCon ? config?.livechatCon : "wss://proxy.uc.dev.tetherfi.cloud", {});
        this.#accessToken = "";
        this.livechatEvents = {
          onStartSuccess: (sid: string): void => {
            // Check if the sid contains a colon and remove it as it doesn't support for click to call
            const cleanedSid = sid.includes(':') ? sid.replace(':', '') : sid;
            this.sdkHandler.onSignalingInitialized({sid: cleanedSid});
          },
          onStartFail: (error: any): void => {
            this.sdkHandler.onSignalingFailed(error);
          },
          onDisconnect: (reason: any): void => {
            this.sdkHandler.onSignalingDisconnected(reason);
          },
          onReconnect: (): void => {
            this.sdkHandler.onSignalingReconnected();
          }
        }
        this.initializeEventHandler();
    }

    initializeEventHandler = () => {
      try {
        this.#client.onStartSuccess = this.livechatEvents.onStartSuccess;
        this.#client.onStartFail = this.livechatEvents.onStartFail;
        this.#client.onDisconnect = this.livechatEvents.onDisconnect;
        this.#client.onReconnect = this.livechatEvents.onReconnect;      
      } catch (error: any) {
        Logger.client.error("livechat-signaling", "initializeEventHandler", `Error initializing livechat events ${error.message}`, error);
      }
    }

    loginToSystem = (userName: string, userPassword: string): Promise<boolean> => {
        return new Promise((resolve: Function, reject: Function) => {
            try {
                let params = {
                    userName,
                    userPassword,
                    authUrl: "https://guardian.uc.dev.tetherfi.cloud",
                };

                this.#client.loginV2(userName, userPassword, (response: any, authToken: any, tokenInfo: any) => {
                    
                    Logger.client.info("livechat-signaling", "loginToSystem", `Calling SDK startSession In Login Method Callback  ${userName}`);
                    if (response) {
                        this.#client.setValidationAttributes({
                          authType: "cloud",
                        });          
                        let newWorkspace = null;
                        let officialOrg = null;
          
                        let usernameSplit = userName.split("@");
          
                        let mydomain = usernameSplit.length === 2 ? usernameSplit[1] : null;
          
                        if (tokenInfo.organizations && tokenInfo.organizations.length > 0) {
                          officialOrg = tokenInfo.organizations.find((org: any) => (org.officialDomain === mydomain ? true : false));
                          newWorkspace = officialOrg ? officialOrg.workSpaceName : tokenInfo.organizations[0].workSpaceName;
                        }
          
                        this.#client.switchWorkspace(
                          "https://guardian.uc.dev.tetherfi.cloud",
                          userName,
                          tokenInfo.refreshToken,
                          newWorkspace,
                          (switchResponse: any, switchTokenInfo: any) => {
                            if (switchResponse && switchTokenInfo) {
                                this.#accessToken = switchTokenInfo.token;
                                tokenInfo.token = switchTokenInfo.token;
                                tokenInfo.organization = switchTokenInfo.organization;
                                Logger.client.info("livechat-signaling", "loginToSystem", `Starting livechat client`);
                                this.#client.start({ name: "Test", address: "Test", override: true }, true);
                                resolve(true);
                            } else {
                                Logger.client.info("livechat-signaling", "loginToSystem", `Starting livechat client`);
                                this.#client.start({ name: "Test", address: "Test", override: true }, true);
                                resolve(true);
                            }
                          }
                        );
                      } else {
                        Logger.client.error("livechat-signaling", "loginToSystem", `No response from login`);
                        reject(new Error("No response from login"));
                      }

                }, params)
                
            } catch (error: any) {
                Logger.client.error("livechat-signaling", "loginToSystem", `Exception ocurred : ${error.message}`, error);
                reject(error);                
            }
        })

    }

    loginWithAppKey = (appKey: string, reqId: string) => {
        return new Promise((resolve, reject) => {
    
          let params = {
            authUrl: `${sdkConfigs.config.getConfig().authserver}/app/auth/token/sdk`,
            tempId: reqId
          };
          try {
            Logger.client.info("livechat-signaling", "loginWithAppKey", "");
            this.#client.loginV3(
              appKey,
              (response: any, accessToken: any, tokenInfo: any) => {
                
                if (response) {
                    this.#accessToken = accessToken;
                    Logger.client.info("livechat-signaling", "loginWithAppKey", `Access token obtained - tenant: ${tokenInfo?.result?.tenant}`);
                    sdkConfigs.config.setConfig('TENANT_ID', tokenInfo?.result?.tenant);
                    this.#client.setValidationAttributes({
                    authType: "cloud",
                  });
                  this.#client.start({ name: "Test", address: "Test", override: true }, true);
                  resolve(true);
                } else {
                  Logger.client.info("livechat-signaling", "loginWithAppKey", `No response from login`);
                  reject(new Error("No response from login"));
                }
              },
              params
            );
          } catch (error: any) {
            Logger.client.error("livechat-signaling", "loginWithAppKey", `Error occurred ${error.message}`, error);
            reject(error);
          }
        });
    };

    initialize = (config: any, reqId: string): Promise<any> => {
        return new Promise((resolve: Function, reject: Function) => {

            try{
                Logger.client.info("livechat-signaling", "initialize", `Starting authentication process : ${JSON.stringify(config)}`);
                if(config.authCode){
                    Logger.client.info("livechat-signaling", "initialize", `Trying to obtain access token`);
                    this.loginWithAppKey(config.authCode, reqId).then((res: any) => {
                    Logger.client.info("livechat-signaling", "initialize", `Access token obtained`);

                    LiveChatAvCommInterface(this.sdkHandler);

                    resolve(true);                 
                }).catch((error: any) => {
                    Logger.client.error("livechat-signaling", "initialize", `Login error ${error.message}`, error);
                    this.sdkHandler.onPhoneEvent("LOGIN_ERROR", error.message);
                    reject(error);
                });

                }else{
                    Logger.client.info("livechat-signaling", "initialize", `Trying to login`);
                    this.loginToSystem("sadun@yopmail.com", "QAZqaz@123").then((res: any) => {
                    Logger.client.info("livechat-signaling", "initialize", `Login successfull`);

                    LiveChatAvCommInterface(this.sdkHandler);

                    resolve(true);                 
                  }).catch((error: any) => {
                      Logger.client.error("livechat-signaling", "initialize", `Login error ${error.message}`, error);
                      this.sdkHandler.onPhoneEvent("LOGIN_ERROR", error.message);
                      reject(error);
                  });

                }               

              }catch(error: any){
                Logger.client.error("livechat-signaling", "initialize", `Livechat initialization error ${error.message}`, error);
                this.sdkHandler.onPhoneEvent("LOGIN_ERROR", error.message);
                reject(error);    
              }

        });
        
    }

    createCall = (avConfig: any): any => {
        try {
            Logger.client.info("livechat-signaling", "createCall", "Trying to set call config");
            let callFactory = new LiveChatAvFactory();
            let call = callFactory.CreateUsingLivechat2(this.#client);
            call.SetAvConfigs(avConfig);
            return call;
            
        } catch (error: any) {
            Logger.client.error("livechat-signaling", "createCall", `Error occurred ${error.message}`, error);   
        }
    }

    dispose = (): void => {
        Logger.client.info("livechat-signaling", "dispose", "Logging out");
        this.#client.end("logout");
    }
}