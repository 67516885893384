import {SDKConfiguration as sdkConfigs}  from '../config/sdk-configuration';

export class ConsoleLogger {

  private generateLogDateTime = () : string => {
    try{
      const date = new Date();
      return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + ":" + date.getMilliseconds()).toString();
    }catch(error: any){
      return "";
    }    
  }

  info = (className: string, methodName: string, message: string): void => {
    console.info(`[${this.generateLogDateTime()}] - [WebPhoneSDK.${className}.${methodName}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
  };
  
  debug = (className: string, methodName: string, message: string): void => {
    console.debug(`[${this.generateLogDateTime()}] - [WebPhoneSDK.${className}.${methodName}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
  };
  
  warn = (className: string, methodName: string, message: string): void => {
    console.warn(`[${this.generateLogDateTime()}] - [WebPhoneSDK.${className}.${methodName}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);
  };
  
  error = (className: string, methodName: string, message: string, error: any = null): void => {
    console.error(`[${this.generateLogDateTime()}] - [WebPhoneSDK.${className}.${methodName}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`, error);  
  };
  
  log = (className: string, methodName: string, message: string) => {
    console.warn(`[${this.generateLogDateTime()}] - [WebPhoneSDK.${className}.${methodName}] - [${sdkConfigs?.config?.getConfig().tenantId}] - ${message}`);  
  };
}
